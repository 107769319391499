import { Flex } from "components/shared/Flex";
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ReactSpeedometer from "react-d3-speedometer"
import { Transition } from 'react-d3-speedometer'
import Helper from "helpers/Helper";

const Wrapper = styled(Flex)`
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    position: relative;
`;

const Border = styled(Flex)`
    position: relative;
    width: 11rem;
    height: 11rem;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 100px;    
    align-items: center;
    justify-content: center;
    background-image: radial-gradient(circle, #e8edf7, #eff1f9, #f5f6fb, #fafafd, #ffffff);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;

    @media (max-width: 500px){
        width: 9.5rem;
        height: 9.5rem;
    }

    @media (max-width: 375px){
        width: 7.5rem;
        height: 7.5rem;
    }

    @media (max-width: 240px){
        width: 4.5rem;
        height: 4.5rem;
    }
`;

const Instructor = styled(Flex)`
    height: 2.5rem;
    width: 6rem;
    bottom: 12px;
    position: absolute;
    font-size: 0.5rem;
    font-weight: 300;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media (max-width: 480px){
        font-size: 0.3rem;
        height: 2rem;
        width: 4.5rem;
        bottom: 13px;
    }
    @media (max-width: 320px){
        font-size: 0.25rem;
        height: 2rem;
        width: 3.3rem;
        bottom: 6px;
    }
    @media (max-width: 240px){
        font-size: 0.15rem;
        height: 2rem;
        width: 2.5rem;
        bottom: 0px;
    }
`;

export default function ProgressBar({ wellnessRange, wellnessRangeStatus }: any) {
    const [width, setWidth] = useState(180);
    const [height, setHeight] = useState(180);
    const [needle, setNeedle] = useState(0.6);
    const [ringWidth, setRingWidth] = useState(13);
    const [textPadding, setTextPadding] = useState(9);
    const [fontSize, setFontsize] = useState(1.063);
    const { innerWidth, innerHeight } = window;

    useEffect(() => {
        //Handle Responsive
        if (innerWidth <= 500 && innerWidth > 375) {
            setWidth(155);
            setHeight(155);
            setNeedle(0.59);
            setFontsize(0.813);
            setTextPadding(0);
            setRingWidth(12);
        } else if (innerWidth <= 375 && innerWidth > 240) {
            setWidth(130);
            setHeight(130);
            setNeedle(0.54);
            setFontsize(0.75);
            setTextPadding(0);
            setRingWidth(10);
        } else if (innerWidth <= 240) {
            setWidth(90);
            setHeight(90);
            setNeedle(0.4);
            setFontsize(0.5);
            setTextPadding(-6);
            setRingWidth(6)
        }
    }, [])

    return (
        <Wrapper>
            <Border>
                <ReactSpeedometer
                    value={wellnessRange}
                    minValue={0}
                    maxValue={10}
                    forceRender={true}
                    width={width}
                    height={height}
                    ringWidth={ringWidth}
                    segments={500}
                    maxSegmentLabels={0}
                    needleTransition={Transition.easeCubicInOut}
                    needleTransitionDuration={1500}
                    currentValueText={`${Helper.upperFirstLetter(wellnessRangeStatus)}`}
                    valueTextFontSize={`${fontSize}rem`}
                    labelFontSize={'0.688rem'}
                    paddingVertical={textPadding}
                    textColor={"#000"}
                    needleHeightRatio={needle}
                    needleColor={"#55545b"}
                    // startColor={"#ff0000"}
                    // endColor={"#00FF00"}
                    startColor={"#e61e0b"}
                    endColor={"#89e935"}
                />
            </Border >
            <Instructor>{`The measured indicators are not intended for medical use`}</Instructor>
        </Wrapper>
    )
}