import axios from 'axios'
import { API_PATH } from 'constant/api/APISetting'
import { FLAG_VALUE, SOURCE_FLAG } from 'constant/env/EnvKey'
import { VitalBypassKey } from 'constant/setting/VitalConstraint'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFound from 'screens/Error/NotFound'
import SessionExpired from 'screens/Error/SessionExpired'
import Unauthorized from 'screens/Error/Unauthorized'
import Measure from 'screens/Measuring/Measure'
import Result from 'screens/Result/Result'
import Review_Callflow from 'screens/Review/Review_Callflow'
import Review_VitalRPM from 'screens/Review/Review_VitalRPM'

export default function RootRoutes() {
    const navigate = useNavigate();
    const [validateSuccess, setValidateSuccess] = useState(false);

    useEffect(() => {
        const validate = async () => {
            //validate URL

            //VITALRPM
            if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) {
                if (
                    (window.location.href.search("vitalsign") != -1 && window.location.href.search("result") == -1)
                    || window.location.href.search("vitalsign/result") != -1
                ) {
                    const token = localStorage.getItem("token");
                    if (token != "null") {
                        //validate PatientID
                        const validateToken = await axios.get(`${API_PATH.getKeyPairValueRPM}/${token}`);
                        if (validateToken.data.result != "{}" ? false : true && window.location.href.search("result") == -1) {
                            navigate("/401");
                        }
                        setValidateSuccess(true);
                    }
                    else {
                        navigate("/404");
                    }
                }
            }
            //CALLFLOW
            else if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
                if (
                    (window.location.href.search("vitalsign") != -1 && window.location.href.search("result") == -1)
                    || window.location.href.search("vitalsign/result") != -1
                ) {

                    const token = localStorage.getItem("token");
                    if (token != "null") {
                        //check bypass
                        // if (token != process.env.REACT_APP_BYPASS_TOKEN) {
                        if (!VitalBypassKey.includes(token)) {
                            //validate callerid
                            const validateToken = await axios.get(`${API_PATH.validation}/${token}`);
                            if (!(validateToken.data.result ? true : false) && window.location.href.search("result") == -1) {
                                navigate("/401");
                            }
                            //validate completed
                            if (localStorage.getItem("vitalsign") == null) {
                                if (window.location.href.search("vitalsign/result") != -1) {
                                    navigate("/404");
                                }
                                const dataResponse = await axios.get(`${API_PATH.validationCompleted}/${token}`);
                                
                                if (dataResponse.data.result == true &&
                                    //bypass review screen
                                    window.location.href.search("vitalsign/review") == -1
                                ) {
                                    navigate("/expired");
                                }
                            }

                            //callAPIAccess
                            if ((window.location.href.search("vitalsign") != -1) && (window.location.href.search("result") == -1) &&
                            (window.location.href.search("vitalsign/review") == -1)) {
                                const dataSending: {} = {
                                    ID: `3;${token}`,
                                    KeyPair: "UserAccess;true",
                                };
                                await axios.post(`${API_PATH.addKeyPairValue}`, dataSending);
                            }
                            //If nothing invalid appears, turn the validate success flag on
                            setValidateSuccess(true);
                        } else {
                            setValidateSuccess(true);
                        }
                    }
                    else {
                        navigate("/404");
                    }
                }
            }
        }
        validate();
    }, []);

    return (

            <Routes>
                <Route path="vitalsign">
                    {validateSuccess && <Route path="" element={<Measure />} />}
                    <Route path="result" element={<Result />} />
                    {(SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) && <Route path="review" element={<Review_Callflow />} />}
                    {(SOURCE_FLAG.sourceFlag == FLAG_VALUE.vitalRPM) && <Route path="review" element={<Review_VitalRPM />} />}
                </Route>
                <Route path="404" element={<NotFound />} />
                <Route path="401" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
                <Route path="expired" element={<SessionExpired />} />
            </Routes>

    )
}
