import red_flag from "../../assets/icon/review/red-flag.svg";
import "./IsabelDescription.css"

export const IsabelDescription: any = {
  ResultDetailTitle: `Get the most out of your symptom checker results`,
  ResultDetail: `
  <div>
    <div>Click on a condition to learn more.</div>
    <br/>
    <div><img className="flag" src=${red_flag} /> conditions are serious and acute and need to be treated in the Emergency Department.</div>
    <br/>
    <div>
      <span> Conditions labelled </span> 
      <span className="common">c</span>
      <span>affect more than 50 people per 100,000. Rare conditions are those affecting fewer than 50 per 100,000 people.</span>
    </div>
  </div>
  `,
  Recommendations: `*This is just a suggestion and must not be relied on over above your own instrinct and judgment`
}