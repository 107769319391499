import { Flex } from "components/shared/Flex";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getIcon, statusDescription } from "constant/setting/VitalDescription";
import { STATUS_KEY } from "constant/setting/VitalConstraint";
import sad from "../../assets/icon/result/sad-face.svg";
import happy from "../../assets/icon/result/happy-face.svg";

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const ChildWrapper = styled(Flex)`
  width: 100%;
  height: 45%;
  justify-content: center;
`;

const IconBorderWrapper = styled(Flex)`
  border-radius: 50px;
  height: 1.6rem;
  width: 1.6rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px){
    height: 1.4rem;
    width: 1.4rem;
  }
  
  @media (max-width: 360px){
    height: 1.3rem;
    width: 1.3rem;
  }

  @media (max-width: 320px){
    height: 1.1rem;
    width: 1.1rem;
  }
  
  @media (max-width: 240px){
    height: 0.6rem;
    width: 0.6rem;
  }
`;

const IconImg = styled.img`
  height: 80%;
  width: auto;
  position: relative;
`;

const CardIconImg = styled.img`
  height: 2.4rem;

  @media (max-width: 480px){
    height: 2.2rem;
  }
  
  @media (max-width: 320px){
    height: 1.8rem;
  }
  
  @media (max-width: 240px){
    height: 1rem;
  }
`;

const IconWrapper = styled(Flex)`
  width: 50%;
  justify-content: center;
  align-items: center;
`;

const TitleWrapper = styled(Flex)`
  height: 30%;
  width: 100%;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const ResultWrapper = styled(Flex)`
  width: 50%;
  flex-direction: row;
`;

const ResultText = styled(Flex)`
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Flex)`
  width: 65%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  font-size: 0.95rem;
  color: #646c7e;
  font-weight: 700;

  @media (max-width: 480px){
      font-size: 0.7rem;
  }

  @media (max-width: 320px){
    font-size: 0.55rem;
  }

  @media (max-width: 240px){
    font-size: 0.3rem;
  }
`;

const Result = styled(Flex)`
  align-items: flex-end;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  height: 40%;
  
  @media (max-width: 480px){
    font-size: 1.4rem;
  }
  @media (max-width: 320px){
    font-size: 0.688rem;
  }
  @media (max-width: 240px){
    font-size: 0.6rem;
  }
`;

const BPResult = styled(Flex)`
  align-items: flex-end;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 600;
  height: 40%;
  
  @media (max-width: 480px){
    font-size: 1.15rem;
  }
  
  @media (max-width: 320px){
    font-size: 0.688rem;
  }
  
  @media (max-width: 240px){
    font-size: 0.6rem;
  }
`;

const Unit = styled(Flex)`
  font-size: 0.6rem;
  align-self: center;
  justify-content: center;
  position: relative;

  @media (max-width: 320px){
    font-size: 0.5rem;
  }

  @media (max-width: 240px){
    font-size: 0.35rem;
  }
`;

const RangeWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  font-size: 1vh;
  font-style: oblique;
  width: 100%;
  height: 25%;
  color: #646c7e;
`;

const Range = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function CardResult({ vitalSign, status, resultTitle, result, unit }) {
  const [icon, setIcon] = useState();

  useEffect(() => {
    setIcon(getIcon(vitalSign));
  }, [vitalSign]);

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          {icon ?
            <IconBorderWrapper>
              <IconImg src={icon} />
            </IconBorderWrapper>
            : <></>
          }
          <Text>{resultTitle}</Text>
        </TitleWrapper>

        <ChildWrapper>
          <ResultWrapper>
            <ResultText>
              {resultTitle == "Blood Pressure" ? <BPResult>{result}</BPResult> : <Result>{result}</Result>}
              {unit ? <Unit>{unit}</Unit> : <Unit>{status.toUpperCase()}</Unit>}
            </ResultText>
          </ResultWrapper>
          <IconWrapper>
            {(status === STATUS_KEY.NORMAL || status === STATUS_KEY.MEDIUM) &&
              <CardIconImg src={happy} />
            }
            {(status === STATUS_KEY.HIGH || status === STATUS_KEY.LOW) &&
              <CardIconImg src={sad} />
            }
          </IconWrapper>
        </ChildWrapper>

        <RangeWrapper>{statusDescription(vitalSign) ? <Range>{statusDescription(vitalSign)}</Range> : <Range>{"--"}</Range>}</RangeWrapper>
      </Wrapper>
    </>
  );
}