import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import loadingGIF from "../../assets/primas/loading.gif" 
const LoadingWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  background: #FFF;
  z-index: 50;
`;
const Loading = styled.img`
  height: 13.5vh;
`;

export default function Loader() {
  return (
    <LoadingWrapper>
      <Loading src={loadingGIF}/>
    </LoadingWrapper>
  );
}
