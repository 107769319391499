import styled from "styled-components";
import { Flex } from "../shared/Flex";
import React from "react";
import Logo from "./Logo";
import SettingsButton from "./SettingsButton";
import TopBarStartButton from "./TopBarStartButton";
import Helper from "helpers/Helper";
import axios from "axios";
import { API_PATH } from "constant/api/APISetting";
import { useNavigate } from "react-router-dom";
import { ENV_VALUE, FLAG_VALUE, SOURCE_FLAG } from "constant/env/EnvKey";
import { SOURCE_PARAM } from '../../constant/env/EnvKey';

const Wrapper = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  min-height: 6vh;
  z-index: 2;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  background: #ffffff;
`;

const WrapperIframe = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  min-height: 6vh;
  z-index: 2;
  /* box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2); */
  /* background: #ffffff; */
`;

const TestButton = styled.button`
  @media (max-width: 700px) {
    width: 20%;
  }

  @media (max-width: 1700px) {
    left: 1vw;
  }

  justify-content: center;
  align-items: center;
  display: flex;
  color: #8ec73d;
  border: 2px solid #8ec73d;
  border-radius: 3px;
  position: absolute;
  right: 7vw;  
  font-size: 2vh;
  margin-right: 200px;
  height: 80%;
  width: 100px;

  background-color: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  /* display: inline-block; */
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  font-weight: 600;
  margin: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;

  &:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }

  &:active {
    background-color: #F7F7F7;
    border-color: #8ec73d;
    transform: scale(.96);
  }

  &:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }
`;

export default function TopBar({ onSettingsClick, isMeasuring, isLoading, isVideoReady, handleButtonClick, isShown }) {
  const navigate = useNavigate();

  const handleTestButtonClicked = () => {
    async function callAPI() {
      const dataSending: {} = {
        ID: `3;${localStorage.getItem(process.env.REACT_APP_BYPASS_KEY)}`,
        KeyPair: Helper.modifiedRequestData(Helper.randomVitalSignData),
      };
      const res = await axios.post(
        `${API_PATH.addKeyPairValueRPM}`,
        dataSending
      );
      return res;
    }
    try {
      callAPI();
      localStorage.setItem("refresh", "0");
      navigate("/vitalsign/result", { state: Helper.randomVitalSignData });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {!(localStorage.getItem(SOURCE_PARAM.iframeParam) == FLAG_VALUE.true) && 
        <Wrapper>
          {!isMeasuring && <SettingsButton onClick={onSettingsClick} />}
          <Logo />
          {
            isShown && isVideoReady 
            && <TopBarStartButton isLoading={isLoading} isMeasuring={isMeasuring} onClick={handleButtonClick} />
          }
        </Wrapper>
      }
      {(localStorage.getItem(SOURCE_PARAM.iframeParam) == FLAG_VALUE.true) &&
        <WrapperIframe>
          {!isMeasuring && <SettingsButton onClick={onSettingsClick} />}
          {/* <Logo /> */}
          {
            isShown && isVideoReady
            && <TopBarStartButton isLoading={isLoading} isMeasuring={isMeasuring} onClick={handleButtonClick} />
          }
        </WrapperIframe>
      }
    </>
  );
}
